.phoneInput input {
  width: auto;
  border-radius: 5px;
  max-width: 196px;
}

.profileRelationButton {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  min-width: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
