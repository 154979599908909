.customCheckBox {
    position: relative;
    width: 100%;
    height: 50px;

}

.customCheckBox label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.customCheckBox input {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 40%;
}


.customStyle {
    /* border: 1px solid var(--border-color); */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    font-size: 14px;
    border-radius: 5px;
    column-gap: 5px;
    min-height: 45px;
}
.customStyle label { 
    margin-bottom: 0 !important;
}