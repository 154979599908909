.moneyInputContainer {
  display: flex;
  align-items: center;
}

.dollarInput {
  width: 80%;
  text-align: right;
  border: 1px solid #ccc;
}
.dollarSign {
  margin-right: 5px;
}

.dot {
  margin: 0 5px;
}

.centsInput {
  width: 30px;
  border: 1px solid #ccc;
}
